
<template>
<div class="card mb-3">
    <div class="card-body">
        <h4>Evidencija vremena</h4>
        <hr>

        <div v-if="settings_data">
            <div class="settings-group">

                <div class="option-field input-group">
                    <p class="setting-desc">Početak radnog vremena <small>(Najraniji mogući dolazak)</small></p>
                    <input type="time" class="form-control settings-value-time" v-model="settings_data.work_day_start" name="work-day-start" id="work-day-start">
                </div>


                <div class="option-field input-group">
                    <p class="setting-desc">Kraj radnog vremena <small>(Najraniji mogući odlazak)</small></p>
                    <input type="time" class="settings-value-time form-control" v-model="settings_data.work_day_end" name="work-day-end" id="work-day-end">
                </div>


                <div class="option-field input-group">
                    <p class="setting-desc">Početak radnog vremena neradnog dana</p>
                    <input type="time" class="form-control settings-value-time" v-model="settings_data.non_working_day_start" name="non-working-day-start" id="non-working-day-start">
                </div>


                <div class="option-field input-group">
                    <p class="setting-desc">Kraj radnog vremena neradnog dana</p>
                    <input type="time" class="settings-value-time form-control" v-model="settings_data.non_working_day_end" name="non-working-day-end" id="non-working-day-end">
                </div>

            </div>

            <div class="settings-group">

                <div class="option-field input-group">
                    <p class="setting-desc">Preciznost zaokruživanja</p>
                    <select name="rounding-precision" id="rounding-precision" v-model="settings_data.rounding_precision" class="form-control settings-round-value">
                        <option value="1">1 min</option>
                        <option value="5">5 min</option>
                        <option value="10">10 min</option>
                        <option value="15">15 min</option>
                        <option value="20">20 min</option>
                        <option value="30">30 min</option>
                        <option value="60">60 min</option>
                    </select>
                </div>


                <div class="option-field input-group">
                    <p class="setting-desc">Zaokruživanje dolaska</p>
                    <select v-model="settings_data.start_rounding_method" name="start-rounding-method" id="start-rounding-method" class="form-control settings-round-value">
                        <option value="L">Na raniju vrednost</option>
                        <option value="N">Na bližu vrednost</option>
                        <option value="U">Na kasniju vrednost</option>
                    </select>
                </div>
                <div class="option-field input-group">
                    <p class="setting-desc">Tolerancija kašnjenja na dolasku </p>
                    <div class="input-sufix min-sufix settings-round-value">
                        <input type="number" step="1" v-model="settings_data.start_tolerance" class="form-control" name="start-tolerance" id="start-tolerance">
                    </div>
                </div>


                <div class="option-field input-group">
                    <p class="setting-desc">Zaokruživanje odlaska</p>
                    <select v-model="settings_data.end_rounding_method" name="end-rounding-method" id="end-rounding-method" class="form-control settings-round-value">
                        <option value="L">Na raniju vrednost</option>
                        <option value="N">Na bližu vrednost</option>
                        <option value="U">Na kasniju vrednost</option>
                    </select>
                </div>
                <div class="option-field input-group">
                    <p class="setting-desc">Tolerancija kašnjenja na odlasku</p>
                    <div class="input-sufix min-sufix settings-round-value">
                        <input type="number" step="1" class="form-control" v-model="settings_data.end_tolerance" name="end-tolerance" id="end-tolerance">
                    </div>
                </div>

            </div>

            <div class="settings-group">
                <div class="option-field input-group">
                    <p class="setting-desc">Dozvoli prekovremene sate ako radi duže od</p>
                    <div class="input-sufix hour-sufix settings-round-value">
                        <input type="number" step="1" class="form-control" v-model="settings_data.overtime_required_hours" name="overtime-required-hours" id="overtime-required-hours">
                    </div>
                </div>
                <div class="option-field input-group">
                    <p class="setting-desc">Dozvoli prekovremene sate ako neradnog dana radi duže od</p>
                    <div class="input-sufix hour-sufix settings-round-value">
                        <input type="number" step="1" class="form-control" v-model="settings_data.overtime_required_hours_nw_day" name="overtime-required-hours" id="overtime-required-hours">
                    </div>
                </div>

            </div>

            <div class="settings-group">
                <div class="option-field input-group">
                    <p class="setting-desc">Koeficijent radnog dana</p>
                    <div class="settings-round-value">
                        <input type="number" step="0.1" min="0.1" max="10" class="form-control" v-model="settings_data.work_day_quota" name="work-day-quota" id="work-day-quota">
                    </div>
                </div>
                <div class="option-field input-group">
                    <p class="setting-desc">Koeficijent neradnog dana</p>
                    <div class="settings-round-value">
                        <input type="number" step="0.1" min="0.1" max="10" class="form-control" v-model="settings_data.non_working_day_quota" name="non-working-day-quota" id="non-working-day-quota">
                    </div>
                </div>
                <div class="option-field input-group">
                    <p class="setting-desc">Koeficijent prekovremenog rada</p>
                    <div class="settings-round-value">
                        <input type="number" step="0.1" min="0.1" max="10" class="form-control" v-model="settings_data.overtime_day_quota" name="overtime-day-quota" id="overtime-day-quota">
                    </div>
                </div>
            </div>

            <button @click="saveSettings" class="btn btn-primary">Sačuvaj</button>
        </div>


    </div>
</div>
</template>

<script>

    import SettingsService from '@/service/SettingsService.js';
    import {mapState} from 'vuex'

    export default {
        
        name: "SettingsGeneral",

        components: {
        },

        data() {
            return {
                // settings_data: {
                //     work_day_start: "07:00",
                //     work_day_end: "17:00",
                //     non_working_day_start: "07:00",
                //     non_working_day_end: "15:00",

                //     rounding_precision: 30,
                //     start_rounding_method: "UPPER",
                //     end_rounding_method: "UPPER",

                //     start_tolerance: 5,
                //     end_tolerance: 5,

                //     overtime_required_hours: 10,
                //     overtime_required_hours_nw_day: 8,

                //     work_day_quota: 1,
                //     non_working_day_quota: 1.5,
                //     overtime_day_quota: 1.5,
                // }

                settings_data: null

            }
        },

        computed: {
            ...mapState([
                'settings',
            ]),

        },


        methods: {
            async loadSettingsData(){
                await this.$store.dispatch('loadSettings');
                console.log(this.settings);

                let settings_data = {}
                this.settings.forEach(opt => {
                    if (opt.section != 'timesheet') return

                    settings_data[opt.name] = opt.value
                });

                this.settings_data = settings_data
            },

            async saveSettings() {
                let payload = {}

                Object.entries(this.settings_data).forEach(entry => {
                    if (!isNaN(entry[1])) {
                        entry[1] = parseFloat(entry[1]) 
                    }

                    payload["timesheet__" + entry[0]] = entry[1]
                })

                try {
                    await SettingsService.updateSettings(payload);
                    this.$toasted.show("Sačuvano", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });

                } catch {
                    this.$toasted.show("Neuspešno čuvanje", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }





            }
        },

        created() {
            this.loadSettingsData();
        },

    }
</script>
